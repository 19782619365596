<script setup lang="ts">
import Button from '@frontend/ui-kit/src/components/Base/Button.vue';
import Chip from '@frontend/ui-kit/src/components/Base/Chip.vue';
import InfoDialog from '@frontend/ui-kit/src/components/Base/InfoDialog.vue';
import { ROUTES } from '~/constants/routes';
import { BookingSteps } from '~/layers/booking/constants/steps';
import { useBookingStore } from '~/layers/booking/stores/bookingStore';
import { useNotificationStore } from '~/stores/alert/useNotificationStore';

const { t } = useI18n();
const bookingStore = useBookingStore();
const { bookingSessionEnd, currentStep } = storeToRefs(bookingStore);
const notificationStore = useNotificationStore();

const isLoading = ref<boolean>(true);
const isTimeUp = ref<boolean>(false); // признак что время вышло
let timer: ReturnType<typeof setInterval>;

const remainingTime = ref<number>(0);
const updateRemainingTime = () => {
    if (bookingSessionEnd.value) {
        const endDate = bookingSessionEnd.value.getTime();
        const now = Date.now();
        remainingTime.value = endDate - now; // Оставшееся время в миллисекундах

        // Если значение меньше нуля, то больше не отсчитывать
        if(remainingTime.value <= 0) {
            clearInterval(timer);

            if(!isFinalStep(currentStep.value)) {
                isTimeUp.value = true;
            }
        }
    }
};

onMounted(() => {
    isLoading.value = false;
    updateRemainingTime();

    timer = setInterval(() => {
        updateRemainingTime(); 
    }, 1000);
});

onBeforeUnmount(() => {
    clearInterval(timer);
});

const timerFormatted = computed(() => {
    const totalSeconds = Math.floor(remainingTime.value / 1000);
    const minutes = String(Math.floor(totalSeconds / 60)).padStart(2, '0');
    const seconds = String(totalSeconds % 60).padStart(2, '0');
    
    return `${minutes}:${seconds}`;
});

const showInfo = () => {
    const notificationObject = {
        title: t("booking.common.timer.title"),
        text: remainingTime.value > 0 ? t("booking.common.timer.processing") : t("booking.common.timer.over"),
    }
    notificationStore.showInformation(notificationObject);
};

// isTimeUp меняется диалогом, если выключается, значит диалог закрыли и надо редиректнуть
watch(isTimeUp, (newValue: boolean, oldValue: boolean) => {
    if (oldValue === true && newValue === false) {
        redirectToBookings();
    }
});

const redirectToBookings = () => {
    bookingSessionEnd.value = null;
    isTimeUp.value = false;
    navigateTo(ROUTES.bookingClient);
}

const isFinalStep = (step: number) : boolean => {
    return step == BookingSteps.SuccesOrFail;
}
</script>

<template>
    <header class="header">
        <div class="header__container">
            <div class="logo">
                <v-skeleton-loader 
                    v-if="isLoading"
                    color="grey50" 
                    type="image" 
                    class="logo-loader"
                />
                <img v-else src="/images/logo.svg">
            </div>
            <div v-if="currentStep !== BookingSteps.SuccesOrFail" class="timer">
                <v-skeleton-loader 
                    v-if="isLoading"
                    color="grey50" 
                    class="timer-loader"
                    type="chip, text" 
                />
                <template v-else>
                    <Chip
                        v-if="remainingTime > 0"
                        class="timer-chip"
                        color="black" 
                        :text="timerFormatted"
                    />
                    <div class="timer-text">{{ remainingTime > 0 ? $t("booking.common.timer.processing") : $t("booking.common.timer.over") }}</div>
                    <i class="ri-information-fill timer-icon" @click="showInfo"></i>
                </template>
            </div>
        </div>

        <InfoDialog
            v-model="isTimeUp"
            styleClass="date-dialog"
            :showCloseButton="false"
        >
            <template #title>
                <div class="dialog-title">{{ $t('booking.client.common.time_is_up.title') }}</div>
            </template>
            <template #content>
                <div class="dialog-text">{{ $t('booking.client.common.time_is_up.description') }}</div>
            </template>
            <template #actions>
                <Button 
                    styleClass="dialog-button"
                    @click="redirectToBookings"
                >
                    {{ $t('forms.shared.phrases.continue') }}
                </Button>
            </template>
        </InfoDialog>
    </header>
</template>

<style scoped lang="scss">
.header {
    width: 100%;

    &__container {
        padding: 20px 64px;
        width: 100%;
        max-width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include responsive('tablet') {
            padding: 20px 24px;
        }
    }

    .logo {
        max-width: 125px;

        &-loader {
            width: 125px;

            :deep(.v-skeleton-loader__bone) {
                margin: 0;
                width: 100%;
                height: 20px;
            }
        }
    }
}

.timer {
    display: flex;
    align-items: center;
    gap: 16px;

    &-text {
        font-size: 12px;
        line-height: 125%;
        letter-spacing: -0.01em;
        color: $color-grey-400;
        max-width: 230px;

        @include responsive("phone") {
            display: none;
        }
    }

    &-icon {
        display: none;
        font-size: 24px;
        color: $color-grey-400;

        @include responsive("phone") {
           display: block;
        }
    }

    &-loader {
        width: 300px;
        display: flex;
        height: 32px;
        background-color: transparent !important;

        @include responsive("phone") {
            width: 140px;
        }

        :deep(.v-skeleton-loader__bone) {
            margin: 0;

            &.v-skeleton-loader__text {
                @include responsive("phone") {
                    margin-left: 12px;
                    max-width: 40px;
                }
            }

        }
    }
}

.dialog-title {
    text-align: left;
}

.dialog-button {
    width: 100%;
}
</style>

